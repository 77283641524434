import type { AxiosInstance } from 'axios'
import { LITHIUM } from '~/constants/urls'
import formatString from '~/utils/formatString'
import { existProperty } from '~/utils/object.utils'
import { formatProducers, makeLink } from '~/utils/lithium/supply/formatProducers'
import type { Demand, DemandData } from '~/types/domain/demand'
import { INITIAL_VALUE_TYPE, ValueType } from '~/constants/general'

export interface Owner {
  name: string
  order: number
  participation: {
    value: number
    unit: string
  }
}

export interface Fact {
  statusWithLaunchYear: string
  currentProduction: {
    weighted: boolean
    year: number
    unit: string
    value: number
  }
  oreTypes: string[]
  operator: string
  owners: Owner[]
  product: string
  nameplateCapacity: {
    weighted: boolean
    year: number
    unit: string
    value: number
  }
}

interface BreadcrumbItem {
  id: string
  label: string
}

interface HeaderResponse {
  header: {
    title: string
    subTitle: string
  }
  facts: Fact
  breadcrumb: {
    companies: BreadcrumbItem[]
    countries: BreadcrumbItem[]
    regions: BreadcrumbItem[]
  }
}

export const supplyMined = (http: AxiosInstance) => ({
  getSupplyMinedOverview: async (valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const { data } = await http.get(LITHIUM.MINED_OVERVIEW, {
      params: { valueType },
    })
    return data
  },
  getMinedSupplyAndDemand: async ({
    scenarioId,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    scenarioId: string
    valueType?: ValueType
  }): Promise<Demand> => {
    const {
      data: { data, $metadata },
    } = await http.get(LITHIUM.MINED_SUPPLY_AND_DEMAND, {
      params: {
        scenarioId,
        valueType,
      },
    })

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      hasWeightedProperty: existProperty(data, 'weighted'),
      demand: data.balance.map((technology) => technology as DemandData),
    }
  },
  getMinedBalance: async ({
    scenarioId,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    scenarioId: string
    valueType?: ValueType
  }): Promise<Demand> => {
    const {
      data: { data, $metadata },
    } = await http.get(LITHIUM.MINED_BALANCE, {
      params: {
        scenarioId,
        valueType,
      },
    })

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      hasWeightedProperty: existProperty(data, 'weighted'),
      demand: data.balance.map((technology) => technology as DemandData),
    }
  },
  getMinedMineOverview: async ({
    groupBy,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    groupBy: string
    valueType?: ValueType
  }) => {
    const { data } = await http.get(LITHIUM.MINED_MINE_OVERVIEW, {
      params: {
        groupBy,
        valueType,
      },
    })
    return data
  },

  getMinedOreTypeTopProducers: async (path, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const {
      data: { data: response },
    } = await http.get(LITHIUM.MINED_ORE_TYPE_OVERVIEW_TOP_PRODUCERS, {
      params: { valueType },
    })
    const { oreTypes, unit } = response
    const producers = formatProducers(oreTypes, unit).map((item) => makeLink(item, path))
    return { ...response, producers }
  },

  getMinedRegionsTopProducers: async (path, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const {
      data: { data: response },
    } = await http.get(LITHIUM.MINED_REGIONS_OVERVIEW, {
      params: { valueType },
    })
    const { regions, unit } = response
    const producers = formatProducers(regions, unit).map((item) => makeLink(item, path))
    return { ...response, producers }
  },

  getMinedCountriesTopProducers: async ({
    path,
    limit,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    path?: string
    limit?: number
    valueType?: ValueType
  }) => {
    const params = limit ? { params: { take: limit, valueType } } : { params: { valueType } }
    const {
      data: { data: response },
    } = await http.get(LITHIUM.MINED_COUNTRIES_OVERVIEW, params)
    const { countries, unit } = structuredClone(response)
    let producers = formatProducers(countries, unit)
    if (path) producers = producers.map((item) => makeLink(item, path))
    return { ...response, producers }
  },

  getMinedCompaniesTopProducers: async ({
    path,
    limit,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    path?: string
    limit?: number
    valueType?: ValueType
  }) => {
    const params = limit ? { params: { take: limit, valueType } } : { params: { valueType } }

    const {
      data: { data: response },
    } = await http.get(LITHIUM.MINED_COMPANIES_OVERVIEW, params)
    const { companies, unit } = structuredClone(response)
    let producers = formatProducers(companies, unit)
    if (path) producers = producers.map((item) => makeLink(item, path))
    return { ...response, producers }
  },

  getMinedMineDetail: async (id: string, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const uri = LITHIUM.MINED_MINE_DETAIL
    const { data: response } = await http.get(formatString(uri, id), {
      params: { valueType },
    })
    return {
      ...response.data,
      hasWeightProperty: existProperty(response.data, 'weighted'),
      sourceData: response.$metadata.sourceData,
    }
  },

  getMinedMineHeaders: async (
    id: string,
    valueType: ValueType = INITIAL_VALUE_TYPE,
  ): Promise<HeaderResponse> => {
    const uri = LITHIUM.MINED_MINE_DETAIL_HEADER
    const { data } = await http.get<HeaderResponse>(formatString(uri, id), {
      params: { valueType },
    })
    return data
  },

  getMinedCompanyDetail: async ({ id, type, valueType = INITIAL_VALUE_TYPE }) => {
    const uri = LITHIUM.MINED_COMPANIES_DETAIL
    const {
      data: { data, $metadata },
    } = await http.get(formatString(uri, id, type), {
      params: { valueType },
    })
    return { data, sourceData: $metadata.sourceData }
  },

  getMinedCompanyHeaders: async (id: string, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const uri = LITHIUM.MINED_COMPANIES_DETAIL_HEADER
    const { data } = await http.get(formatString(uri, id), {
      params: { valueType },
    })
    return data
  },

  getMinedCountryDetail: async ({ id, type, valueType = INITIAL_VALUE_TYPE }) => {
    const uri = LITHIUM.MINED_COUNTRY_DETAIL
    const {
      data: { data, $metadata },
    } = await http.get(formatString(uri, id, type), {
      params: { valueType },
    })
    return { data, sourceData: $metadata.sourceData }
  },

  getMinedCountryHeaders: async (id: string, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const uri = LITHIUM.MINED_COUNTRY_DETAIL_HEADER
    const { data } = await http.get(formatString(uri, id), {
      params: { valueType },
    })
    return data
  },

  getMinedRegionDetail: async ({ id, type, valueType = INITIAL_VALUE_TYPE }) => {
    const uri = formatString(LITHIUM.MINED_REGION_DETAIL, id, type)
    const {
      data: { data, $metadata },
    } = await http.get(formatString(uri, id, type), {
      params: { valueType },
    })
    return { data, sourceData: $metadata.sourceData }
  },

  getMinedRegionHeaders: async (id: string, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const uri = formatString(LITHIUM.MINED_REGION_DETAIL_HEADER, id)
    const { data } = await http.get(uri, {
      params: { valueType },
    })
    return data
  },

  getMineOreTypeHeaders: async (id: string, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const uri = formatString(LITHIUM.MINED_ORE_TYPE_DETAIL_HEADER, id)
    const { data } = await http.get(uri, {
      params: { valueType },
    })
    return data
  },

  getMineOreTypeDetail: async ({ id, type, valueType = INITIAL_VALUE_TYPE }) => {
    const uri = formatString(LITHIUM.MINED_ORE_TYPE_DETAIL, id, type)
    const {
      data: { data, $metadata },
    } = await http.get(uri, {
      params: { valueType },
    })
    return { data, sourceData: $metadata.sourceData }
  },
})
