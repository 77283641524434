import type { AxiosInstance } from 'axios'
import { supplyMined } from '~/services/http/lithium/supply/mined'
import { supplyChemical } from '~/services/http/lithium/supply/chemical'
import { general } from '~/services/http/lithium/general'
import { prices } from '~/services/http/lithium/prices'
import { demand } from '~/services/http/lithium/demand'
import { costsModel } from '~/services/http/lithium/costsModel'

const useLithium = () => {
  const axios = useNuxtApp().$axios as AxiosInstance

  return {
    ...demand(axios),
    ...supplyMined(axios),
    ...supplyChemical(axios),
    ...general(axios),
    ...prices(axios),
    ...costsModel(axios),
  }
}

export default useLithium
