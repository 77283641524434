import type { AxiosInstance } from 'axios'
import { INITIAL_VALUE_TYPE, ValueType } from '~/constants/general'
import { LITHIUM } from '~/constants/urls'
import type { ChemicalOverview } from '~/types/domain/chemicalOverview'
import type { Demand, DemandData } from '~/types/domain/demand'
import formatString from '~/utils/formatString'
import { formatProducers, makeLink } from '~/utils/lithium/supply/formatProducers'

export interface Owner {
  name: string
  order: number
  participation: {
    value: number
    unit: string
  }
}

export interface Fact {
  statusWithLaunchYear: string
  currentProduction: {
    weighted: boolean
    year: number
    unit: string
    value: number
  }
  feedStock: string[]
  operator: string
  owners: Owner[]
  product: string
}

interface BreadcrumbItem {
  id: string
  label: string
}

interface HeaderResponse {
  header: {
    title: string
    subTitle: string
  }
  facts: Fact
  breadcrumb: {
    companies: BreadcrumbItem[]
    countries: BreadcrumbItem[]
    regions: BreadcrumbItem[]
  }
}

export const supplyChemical = (http: AxiosInstance) => ({
  getChemicalBalance: async ({
    scenarioId,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    scenarioId: string
    valueType?: ValueType
  }): Promise<Demand> => {
    const {
      data: { data, $metadata },
    } = await http.get(LITHIUM.CHEMICAL_BALANCE, {
      params: {
        scenarioId,
        valueType,
      },
    })

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      demand: data.balance.map((technology) => technology as DemandData),
    }
  },
  getChemicalSupplyAndDemand: async ({
    scenarioId,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    scenarioId: string
    valueType?: ValueType
  }): Promise<Demand> => {
    const {
      data: { data, $metadata },
    } = await http.get(LITHIUM.CHEMICAL_SUPPLY_AND_DEMAND, {
      params: {
        scenarioId,
        valueType,
      },
    })

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      hasWeightedProperty: existProperty(data, 'weighted'),
      demand: data.balance.map((technology) => technology as DemandData),
    }
  },
  getChemicalSupplyAndDemandDetailed: async ({
    scenarioId,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    scenarioId: string
    valueType?: ValueType
  }) => {
    const {
      data: { data, $metadata },
    } = await http.get(LITHIUM.CHEMICAL_SUPPLY_AND_DEMAND_DETAILED, {
      params: {
        scenarioId,
        valueType,
      },
    })

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      hasWeightedProperty: existProperty(data, 'weighted'),
      demand: data.balance.map((technology) => technology as DemandData),
    }
  },
  getSupplyChemicalOverview: async (
    valueType: ValueType = INITIAL_VALUE_TYPE,
  ): Promise<ChemicalOverview> => {
    const { data } = await http.get<ChemicalOverview>(LITHIUM.CHEMICAL_OVERVIEW, {
      params: { valueType },
    })
    return data
  },
  getChemicalPlantOverview: async ({
    groupBy,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    groupBy: string
    valueType?: ValueType
  }) => {
    const { data } = await http.get(LITHIUM.CHEMICAL_PLANTS_OVERVIEW, {
      params: {
        groupBy,
        valueType,
      },
    })
    return data
  },
  getChemicalPlantsTopProducers: (params) => http.get(LITHIUM.CHEMICAL_PLANTS_OVERVIEW, { params }),
  getChemicalCompaniesTopProducers: async ({
    path,
    limit,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    path?: string
    limit?: number
    valueType?: ValueType
  }) => {
    const params = limit ? { params: { take: limit, valueType } } : { params: { valueType } }
    const {
      data: { data: response },
    } = await http.get(LITHIUM.CHEMICAL_COMPANIES_OVERVIEW, params)
    const { companies, unit } = structuredClone(response)
    let producers = formatProducers(companies, unit)
    if (path) producers = producers.map((item) => makeLink(item, path))
    return { ...response, producers }
  },
  getChemicalFeedstockProducers: async (path, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const {
      data: { data: response },
    } = await http.get(LITHIUM.CHEMICAL_FEEDSTOCKS_OVERVIEW, {
      params: { valueType },
    })

    const { feedstocks, unit } = response
    const producers = formatProducers(feedstocks, unit).map((item) => makeLink(item, path))
    delete response.feedstocks
    return { ...response, producers }
  },
  getChemicalProductProducers: async ({
    path,
    limit,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    path?: string
    limit?: number
    valueType?: ValueType
  }) => {
    const params = limit ? { params: { take: limit, valueType } } : { params: { valueType } }
    const {
      data: { data: response },
    } = await http.get(LITHIUM.CHEMICAL_PRODUCTS_OVERVIEW, params)
    const { products, unit } = response
    let producers = formatProducers(products, unit)
    if (path) producers = producers.map((item) => makeLink(item, path))
    delete response.products
    return { ...response, producers }
  },
  getChemicalCountriesTopProducers: async ({
    path,
    limit,
    valueType = INITIAL_VALUE_TYPE,
  }: {
    path?: string
    limit?: number
    valueType?: ValueType
  }) => {
    const params = limit ? { params: { take: limit, valueType } } : { params: { valueType } }
    const {
      data: { data: response },
    } = await http.get(LITHIUM.CHEMICAL_COUNTRIES_OVERVIEW, params)
    const { countries, unit } = structuredClone(response)
    let producers = formatProducers(countries, unit)
    if (path) producers = producers.map((item) => makeLink(item, path))
    return { ...response, producers }
  },
  getChemicalRegionsTopProducers: async (path, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const {
      data: { data: response },
    } = await http.get(LITHIUM.CHEMICAL_REGIONS_OVERVIEW, {
      params: { valueType },
    })
    const { regions, unit } = response
    const producers = formatProducers(regions, unit).map((item) => makeLink(item, path))
    return { ...response, producers }
  },
  getChemicalPlantDetail: async (id: string, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const uri = LITHIUM.CHEMICAL_PLANT_DETAIL
    const {
      data: { data, $metadata },
    } = await http.get(formatString(uri, id), {
      params: { valueType },
    })
    return { data, sourceData: $metadata.sourceData }
  },
  getChemicalPlantHeaders: async (
    id: string,
    valueType: ValueType = INITIAL_VALUE_TYPE,
  ): Promise<HeaderResponse> => {
    const uri = LITHIUM.CHEMICAL_PLANT_DETAIL_HEADER
    const { data } = await http.get<HeaderResponse>(formatString(uri, id), {
      params: { valueType },
    })
    return data
  },
  getChemicalCompanyDetail: async ({ id, type, valueType = INITIAL_VALUE_TYPE }) => {
    const uri = formatString(LITHIUM.CHEMICAL_COMPANIES_DETAIL, id, type)
    const {
      data: { data, $metadata },
    } = await http.get(uri, {
      params: { valueType },
    })
    return { data, sourceData: $metadata.sourceData }
  },
  getChemicalCompanyHeader: async (id: string, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const uri = formatString(LITHIUM.CHEMICAL_COMPANIES_DETAIL_HEADER, id)
    const { data } = await http.get(uri, {
      params: { valueType },
    })
    return data
  },
  getChemicalCountryDetail: async ({ id, type, valueType = INITIAL_VALUE_TYPE }) => {
    const uri = formatString(LITHIUM.CHEMICAL_COUNTRY_DETAIL, id, type)
    const {
      data: { data, $metadata },
    } = await http.get(uri, {
      params: { valueType },
    })
    return { data, sourceData: $metadata.sourceData }
  },
  getChemicalCountryHeaders: async (id: string, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const uri = formatString(LITHIUM.CHEMICAL_COUNTRY_DETAIL_HEADER, id)
    const { data } = await http.get(uri, {
      params: { valueType },
    })
    return data
  },
  getChemicalRegionDetail: async ({ id, type, valueType = INITIAL_VALUE_TYPE }) => {
    const uri = formatString(LITHIUM.CHEMICAL_REGION_DETAIL, id, type)
    const {
      data: { data, $metadata },
    } = await http.get(uri, {
      params: { valueType },
    })
    return { data, sourceData: $metadata.sourceData }
  },
  getChemicalRegionHeaders: async (id: string, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const uri = formatString(LITHIUM.CHEMICAL_REGION_DETAIL_HEADER, id)
    const { data } = await http.get(uri, {
      params: { valueType },
    })
    return data
  },
  getChemicalFeedstockHeaders: async (id: string, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const uri = formatString(LITHIUM.CHEMICAL_FEEDSTOCK_DETAIL_HEADER, id)
    const { data } = await http.get(uri, {
      params: { valueType },
    })
    return data
  },
  getChemicalFeedstockDetail: async ({ id, type, valueType = INITIAL_VALUE_TYPE }) => {
    const uri = formatString(LITHIUM.CHEMICAL_FEEDSTOCK_DETAIL, id, type)
    const {
      data: { data, $metadata },
    } = await http.get(uri, {
      params: { valueType },
    })
    return { data, sourceData: $metadata.sourceData }
  },
  getChemicalProductHeaders: async (id: string, valueType: ValueType = INITIAL_VALUE_TYPE) => {
    const uri = formatString(LITHIUM.CHEMICAL_PRODUCT_DETAIL_HEADER, id)
    const { data } = await http.get(uri, {
      params: { valueType },
    })
    return data
  },
  getChemicalProductDetail: async ({ id, type, valueType = INITIAL_VALUE_TYPE }) => {
    const uri = formatString(LITHIUM.CHEMICAL_PRODUCT_DETAIL, id, type)
    const {
      data: { data, $metadata },
    } = await http.get(uri, {
      params: { valueType },
    })
    return { data, sourceData: $metadata.sourceData }
  },
})
