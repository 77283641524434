import { Decimal } from 'decimal.js'
import numberWithCommas from '~/utils/numberWithCommas'
import toDecimalPlaces from '~/utils/chartData/toDecimalPlaces'

export const calculatePercentage = (total, production) => {
  if (!total) return ''
  const formatterProduction = new Decimal(production)
  const marketShare = formatterProduction.dividedBy(total).times(100).toNumber()
  return marketShare
}

export const getTotalProducers = (producers) => {
  return producers.reduce((acc, item) => acc + Number(item.production), 0)
}

const slugNames = [
  'companySlug',
  'countrySlug',
  'regionSlug',
  'feedstockSlug',
  'productSlug',
  'oretypeSlug',
]
const producerNames = [
  'producer',
  'company',
  'country',
  'region',
  'feedstock',
  'product',
  'oretype',
]

export const formatProducer = ({ item, unit }) => {
  const productionAsDecimal = new Decimal(item.production)
  const production = toDecimalPlaces(productionAsDecimal.toNumber(), unit, true)
  const productionWithUnit = `${numberWithCommas(production)} ${unit}`

  const currentSlug = Object.keys(item).find((key) => slugNames.includes(key))
  let slug = ''
  if (currentSlug) {
    slug = item[currentSlug]
    delete item[currentSlug]
  }

  const currentName = Object.keys(item).find((key) => producerNames.includes(key))
  let name = ''
  if (currentName) {
    name = item[currentName]
    delete item[currentName]
  }
  return { ...item, productionWithUnit, slug, name, formattedProduction: production }
}

export const makeLink = (item, path) => {
  const link = path.replace('overview', item.slug)
  return { ...item, link }
}

export const formatProducers = (producers, unit) => {
  if (!producers) return []

  const total = getTotalProducers(producers)

  return producers.map((item) => {
    const marketShare = calculatePercentage(total, item.production)
    const producer = formatProducer({ item, unit })
    return { ...producer, marketShare }
  })
}

export const weightedProduction = (hasWeightProperty, weighted, text) => {
  if (!hasWeightProperty) return text

  return weighted ? `${text}*` : `${text}†`
}
