import type { AxiosInstance } from 'axios'
import { format, parseISO } from 'date-fns'
import { LITHIUM } from '~/constants/urls'
import type { Demand, DemandData } from '~/types/domain/demand'
import type {
  DemandByBatteryResponse,
  DemandByChemistryResponse,
  DemandByGeographyResponse,
  DemandByTechnologyChangeResponse,
} from '~/types/dto/demandResponse'
import { existProperty } from '~/utils/object.utils'

export const demand = (axios: AxiosInstance) => ({
  getCellDemandGeography: async () => {
    const {
      data: { data: response },
    } = await axios.get(LITHIUM.CELL_DEMAND_BY_GEOGRAPHY)
    return response
  },
  getEvGlobalSales: async () => {
    const {
      data: { data: response },
    } = await axios.get(LITHIUM.EV_GLOBAL_SALES)

    return response
  },
  getLongTermEvSales: async (params: { scenarioId: string }): Promise<Demand> => {
    const {
      data: { data, $metadata },
    } = await axios.get(LITHIUM.EV_SALES, {
      params,
    })

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      demand: data.vehiclesTypes.map((technology) => technology as DemandData),
    }
  },
  getLongTermEvSalesMonthly: async (params: {
    scenarioId: string
    startYear?: number
    endYear?: number
  }): Promise<Demand> => {
    const {
      data: { data, $metadata },
    } = await axios.get(LITHIUM.MONTHLY_EV_SALES, {
      params,
    })

    const demand = data.vehiclesTypes.map(({ name, data }) => {
      let normalizedData = {}

      data.forEach(({ name: dataName, data }) => {
        const year = format(parseISO(dataName), 'yyyy')
        normalizedData[year] = [...(normalizedData[year] || []), data]
      })

      normalizedData = Object.entries(normalizedData).map(([year, data]) => ({
        year: Number(year),
        value: data,
      }))

      return { name, data: normalizedData }
    })

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      demand,
    }
  },
  getMarket: async (scenarioId: string): Promise<Demand> => {
    const {
      data: { data, $metadata },
    } = await axios.get(LITHIUM.MARKET, {
      params: {
        scenarioId,
      },
    })

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      demand: data.markets.map((technology) => technology as DemandData),
    }
  },
  getChemistry: async (scenarioId: string): Promise<Demand> => {
    const {
      data: { data, $metadata },
    } = await axios.get<DemandByChemistryResponse>(LITHIUM.CHEMISTRY, {
      params: {
        scenarioId,
      },
    })

    const demand = data.chemistries.map((item) => ({
      name: item.name,
      data: item.data.map((datum) => ({
        year: datum.name,
        value: datum.data,
      })),
    }))

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      demand,
    }
  },
  getBattery: async (scenarioId: string): Promise<Demand> => {
    const {
      data: { data, $metadata },
    } = await axios.get<DemandByBatteryResponse>(LITHIUM.BATTERY, {
      params: {
        scenarioId,
      },
    })

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      demand: data.batteryTypes.map((technology) => technology as DemandData),
    }
  },
  getBatteryProductionCurrentYear: async () => {
    const {
      data: { data: response },
    } = await axios.get(LITHIUM.BATTERY_PRODUCTION_CURRENT_YEAR)
    return response
  },
  getEndMarket: async () => {
    const {
      data: { data: response },
    } = await axios.get(LITHIUM.END_MARTKET)
    return response
  },
  getGeographyDemand: async (scenarioId: string): Promise<Demand> => {
    const {
      data: { data, $metadata },
    } = await axios.get<DemandByGeographyResponse>(LITHIUM.GEOGRAPHY_DEMAND, {
      params: { scenarioId },
    })

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      demand: data.regions.map((technology) => technology as DemandData),
    }
  },
  getTechnologyChange: async (scenarioId: string): Promise<Demand> => {
    const {
      data: { data, $metadata },
    } = await axios.get<DemandByTechnologyChangeResponse>(LITHIUM.TECHNOLOGY_CHANGE, {
      params: { scenarioId },
    })

    return {
      quarter: $metadata.sourceData.quarter,
      year: $metadata.sourceData.year,
      unit: data.unit,
      isWeighted: existProperty(data, 'weighted') ? data.weighted : false,
      demand: data.technologies.map((technology) => technology as DemandData),
    }
  },
})
